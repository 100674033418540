<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
    <b-alert :show="errors.length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні!
      </h4>
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </b-alert>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        v-for="(question, index) in form"
        :key="question.value"
        :label="question.name"
      >
        <div v-if="question.is_multiselect">
          <ValidationProvider
            name="відповідь"
            v-slot="{ errors, dirty, validated, valid, changed }"
            :rules="{ required: true }"
            :vid="question.value.toString()"
          >
            <b-form-checkbox-group
              v-model="selectedAnswers[index][question.value].selectedValue"
              :name="question.value.toString()"
              :options="question.answers"
              text-field="name"
              value-field="value"
              :disabled="propGlobalDisabled"
              stacked
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            >
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-checkbox-group>
          </ValidationProvider>
        </div>
        <div v-else>
          <ValidationProvider
            name="відповідь"
            v-slot="{ errors, dirty, validated, valid, changed }"
            :rules="{ required: true }"
            :vid="question.value.toString()"
          >
            <b-form-radio-group
              v-model="selectedAnswers[index][question.value].selectedValue"
              :name="question.value.toString()"
              text-field="name"
              value-field="value"
              stacked
            >
              <b-form-radio
                v-for="(answer, idx) in question.answers"
                :value="answer.value"
                :key="idx + question.value"
                :disabled="propGlobalDisabled"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                {{ answer.name }}
              </b-form-radio>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-radio-group>
          </ValidationProvider>
          <ValidationProvider
            name="q006"
            v-slot="{ errors, dirty, validated, valid, changed }"
            :rules="{ required: true }"
            v-if="
              question.value.toString() ===
                $stringConstants('QUESTION_WITH_REQUIRED_NOTE') &&
                selectedAnswers[index][
                  question.value
                ].selectedValue.toString() ===
                  $stringConstants('ANSWER_WITH_REQUIRED_NOTE') &&
                selectedAnswers[index][question.value].hasOwnProperty('q006')
            "
          >
            <b-form-input
              v-model="selectedAnswers[index][question.value].q006"
              :disabled="propGlobalDisabled"
              type="number"
              min="0"
              step="1"
              placeholder="Кількість інших СПФМ"
              trim
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </b-form-group>
      <b-button
        type="submit"
        size="sm"
        variant="success"
        v-if="!propGlobalDisabled"
      >
        <i class="fa fa-dot-circle-o"></i> Згенерувати
      </b-button>
    </b-form>
  </ValidationObserver>
</template>
<script>
import mixins from "@/mixins";
export default {
  name: "QuestionsModal",
  mixins: [mixins],
  props: {
    propQuestions: {
      type: Object | null,
      required: true
    },
    propGlobalDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      errors: [],
      form: {},
      selectedAnswers: []
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.propQuestions));

    this.form.forEach((obj, key) => {
      const hasQ006 = obj.answers.some(answer => "q006" in answer);
      const findQ006 = obj.answers
        .filter(answer => answer.q006)
        .map(val => val.q006);
      const findSelected = obj.answers
        .filter(answer => answer.selected)
        .map(val => val.value);

      this.selectedAnswers[key] = {
        [obj.value]: {
          selectedValue: obj.is_multiselect
            ? findSelected.length
              ? findSelected
              : []
            : findSelected.length
            ? findSelected[0]
            : "",
          ...(hasQ006 && !obj.is_multiselect
            ? { q006: findQ006.length ? findQ006.toString() : null }
            : {})
        }
      };
    });
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.prepareReportData());
    },
    prepareReportData() {
      this.form.forEach((question, keyQuestion) => {
        const selectedValue = this.selectedAnswers[keyQuestion][question.value]
          .selectedValue;
        const q006 = this.selectedAnswers[keyQuestion][question.value].q006;

        question.answers.forEach(answer => {
          if (Array.isArray(selectedValue)) {
            answer.selected = selectedValue.includes(answer.value);
          } else {
            answer.selected =
              answer.value ===
              this.selectedAnswers[keyQuestion][question.value].selectedValue;

            if (
              q006 &&
              selectedValue.toString() ===
                this.$stringConstants("ANSWER_WITH_REQUIRED_NOTE") &&
              question.value.toString() ===
                this.$stringConstants("QUESTION_WITH_REQUIRED_NOTE") &&
              answer.value.toString() ===
                this.$stringConstants("ANSWER_WITH_REQUIRED_NOTE")
            ) {
              answer.q006 = q006;
            }
          }
        });
      });

      return this.form;
    }
  }
};
</script>
