<template>
  <ValidationObserver ref="formValidation" v-slot="{ handleSubmit }">
    <error-alert
      v-if="errors.code !== 400"
      :errors="errors"
      message="Виникла помилка при додаванні ISEI даних!"
    />
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        name="key6_dat"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="key6_dat"
      >
        <b-form-group label="Особистий ключ (Key-6.dat):">
          <b-form-file
            v-model="form.key6_dat"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="key6_password"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="key6_password"
      >
        <b-form-group label="Пароль до особистого ключа:">
          <b-form-input
            type="password"
            v-model="form.key6_password"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="encryption_crt"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="encryption_crt"
      >
        <b-form-group label="Сертифікат шифрування:">
          <b-form-file
            v-model="form.encryption_crt"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="signature_crt"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="signature_crt"
      >
        <b-form-group label="Сертифікат підпису:">
          <b-form-file
            v-model="form.signature_crt"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="client_id"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="client_id"
      >
        <b-form-group label="Ідентифікатор прикладної системи (ІСЕІ):">
          <b-form-input
            type="text"
            v-model="form.client_id"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="secret_id"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="secret_id"
      >
        <b-form-group label="Секретна строчка доступу(ІСЕІ):">
          <b-form-input
            type="text"
            v-model="form.secret_id"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="redirect_uri"
        :rules="{ required: true, max: 255 }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="redirect_uri"
      >
        <b-form-group label="Зворотне посилання (URI):">
          <b-form-input
            type="text"
            v-model="form.redirect_uri"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-button type="submit" size="sm" variant="success">
        <i class="fa fa-dot-circle-o"></i> Додати
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "CreateIseiForm",
  mixins: [mixins],
  data() {
    return {
      errors: {},
      form: {
        key6_dat: null,
        key6_password: "",
        encryption_crt: null,
        signature_crt: null,
        client_id: "",
        secret_id: "",
        redirect_uri: "",
      },
    };
  },
  methods: {
    test() {
      this.$parent.$parent.hide();
    },
    onSubmit() {
      const formData = new FormData();
      formData.append("financial_model_id", this.$route.params.id);
      formData.append("key6_dat", this.form.key6_dat);
      formData.append("key6_password", this.form.key6_password);
      formData.append("encryption_crt", this.form.encryption_crt);
      formData.append("signature_crt", this.form.signature_crt);
      formData.append("client_id", this.form.client_id);
      formData.append("secret_id", this.form.secret_id);
      formData.append("redirect_uri", this.form.redirect_uri);

      this.$store
        .dispatch("financialModel/createIsei", formData)
        .then(() => {
          this.$store
            .dispatch("financialModel/pullIseis", this.$route.params.id)
            .then(() => {
              this.isBusy = false;
              this.$parent.$parent.hide();
              this.$snotify.success("ISEI дані збережено");
            });
        })
        .catch(({ response }) => {
          this.errors = {};

          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidation.setErrors(this.errors);
          this.$snotify.error("Не збережено");
        });
    },
  },
};
</script>
