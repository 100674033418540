<template>
  <stamps />
</template>
<script>
import Stamps from "@/entity/FinancialModels/components/Stamps.vue";

export default {
  name: "ManagementOfSealsProfile",
  components: { Stamps }
};
</script>
