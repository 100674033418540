<template>
  <b-card>
    <template v-if="isBusy">
      <div class="text-center text-dark my-2">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Завантаження...</strong>
      </div>
    </template>

    <template v-else>
      <ValidationProvider
        name="доступні зовнішні тенанти"
        v-slot="{ errors, dirty }"
        vid="external_tenants"
      >
        <b-form-group label="Доступні зовнішні тенанти">
          <b-form-checkbox-group
            v-model="selectedExternalTenants"
            :options="allExternalTenants"
            value-field="id"
            text-field="name"
            stacked
            :state="setValidationState(errors, dirty)"
          >
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-checkbox-group>
        </b-form-group>
      </ValidationProvider>

      <b-button
        type="submit"
        size="sm"
        variant="primary"
        :disabled="disabledButton"
        @click="storeExternalTenants"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </template>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import mixins from "@/mixins";

export default {
  name: "Settings",
  data() {
    return {
      isBusy: true,
      disabledButton: true,
      selectedExternalTenants: [],
    };
  },
  computed: {
    ...mapGetters({
      allExternalTenants: "dictionary/allContractExternalTenants",
      spfmCheckedExternalTenants: "financialModel/getExternalTenants",
    }),
    selectedExternalTenantsIds() {
      return this.spfmCheckedExternalTenants.map((tenant) => tenant.id);
    },
  },
  methods: {
    storeExternalTenants() {
      this.$store
        .dispatch("financialModel/storeExternalTenants", {
          financial_model_id: this.$route.params.id,
          external_tenant_id: this.selectedExternalTenants,
        })
        .then(() => {
          this.$snotify.success("Дані збережено");
          this.disabledButton = true;
        });
    },
  },
  mixins: [mixins],
  created() {
    this.isBusy = true;
    this.$store
      .dispatch("financialModel/pullExternalTenants", this.$route.params.id)
      .then(() => {
        this.isBusy = false;
        this.selectedExternalTenants = this.selectedExternalTenantsIds;
      });
  },
  watch: {
    selectedExternalTenants() {
      if (
        this.selectedExternalTenantsIds.length !==
          this.selectedExternalTenants.length ||
        ![...this.selectedExternalTenantsIds]
          .sort()
          .every(
            (element, index) =>
              element === [...this.selectedExternalTenants].sort()[index]
          )
      ) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
      }
    },
  },
};
</script>
