var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v("Профіль СПФМ "+_vm._s(_vm.financialModel.name))])]),_c('b-tabs',[_c('b-tab',{attrs:{"title":"Загальна інформація","active":""}},[_c('main-profile',{attrs:{"financial-model":_vm.financialModel}})],1),_c('b-tab',{attrs:{"title":"Параметри звітності"}},[_c('reporting-profile',{attrs:{"financial-model":_vm.financialModel}})],1),_c('b-tab',{attrs:{"title":"Ризик-профіль установи","lazy":""}},[_c('institutions-risk-profile')],1),(
            _vm.$auth.can(_vm.$stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))
          )?_c('b-tab',{attrs:{"title":"Управління печатками"}},[_c('management-of-seals-profile')],1):_vm._e(),_c('b-tab',{attrs:{"title":"Облікові дані ІСЕІ","lazy":""}},[_c('isei-data')],1),(
            _vm.$auth.can(
              _vm.$stringConstants(
                'PERMISSION_SET_FINANCIAL_MODEL_NBU_CREDENTIALS'
              )
            )
          )?_c('b-tab',{attrs:{"title":"Облікові дані НБУ"}},[_c('nbu-credentials',{attrs:{"financial-model":_vm.financialModel}})],1):_vm._e(),_c('b-tab',{attrs:{"title":"Налаштування","lazy":""}},[_c('settings')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }