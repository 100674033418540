<template>
  <b-form-group
    id="nbu-credentials"
    hide-footer
  >
    <error-alert
      :errors="errors"
      message="Виникла помилка оновлення облікового запису НБУ!"
    />
    <ValidationObserver
      ref="nbu-credentials-modal-form"
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)" class="mb-0">
        <ValidationProvider
          vid="nbu_username"
          name="Логін користувача на порталі"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_NAME'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty, validated, valid, changed }"
        >
          <b-form-group
            label="Логін користувача на порталі"
            label-cols="5"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="form.nbu_username"
              class="form__input"
              :state="setValidationState(errors, dirty, validated, valid, changed)"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="nbu_password"
          name="Пароль користувача на порталі"
          rules="required|password"
          v-slot="{ errors, dirty, validated, valid, changed }"
        >
          <b-form-group
            label="Пароль користувача на порталі"
            label-cols="5"
            :horizontal="true"
          >
            <div class="password-input-container">
              <b-form-input
                :type="showPassword ? 'text' : 'password'"
                v-model="form.nbu_password"
                :state="setValidationState(errors, dirty, validated, valid, changed)"
                placeholder="Пароль користувача на порталі"
              />
              <b-button
                @click="switchVisibility()"
                variant="link"
                class="password-toggle-btn"
              >
                {{ showPassword ? '👁️' : '👁️‍🗨️' }}
              </b-button>
            </div>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div slot="footer" class="d-flex justify-content-center">
          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_SET_FINANCIAL_MODEL_NBU_CREDENTIALS'))"
            size="sm"
            type="submit"
            variant="success"
            :disabled="submitting"
          >
            Підтвердити
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-form-group>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert.vue";
import mixins from "@/mixins";

export default {
  name: "NbuCredentials",
  props: ["financialModel"],
  mixins: [mixins],
  components: { ErrorAlert },
  computed: {},
  data() {
    return {
      form: {
        nbu_username: "",
        nbu_password: "",
      },
      showPassword: false,
      submitting: false,
      errors: {},
    };
  },
  methods: {
    switchVisibility() {
      this.showPassword = !this.showPassword;
    },
    onSubmit() {
      this.submitting = true;
      this.errors = {};
      this.form.financial_model_id = this.$route.params.id;

      this.$store
        .dispatch(
          "financialModel/syncNbuCredentials",
          this.form
        )
        .then(() => {
            this.$store.dispatch("financialModels/pull");
            this.$snotify.success("Дані облікового запису оновлено.");
          }
        )
        .catch((error) => {
          this.$snotify.error("Помилка оновлення даних облікового запису");
          this.errors = {
            code: error.response.status,
            description:
              error.response.status === 422
                ? error.response.data.errors
                : error.response.statusText,
          };
        })
        .finally(() => (this.submitting = false));
    },
    decodeFromBase64(str) {
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
  },
  watch: {
    financialModel: {
      immediate: true,
      handler(newVal) {
        this.form.nbu_username = newVal.nbu_username ?? "";
        this.form.nbu_password = newVal.nbu_password ? this.decodeFromBase64(newVal.nbu_password) : "";
      }
    }
  },
};
</script>
<style scoped>
.password-input-container {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.2em;
}

.password-toggle-btn:focus {
  outline: none;
  box-shadow: none;
}
</style>
