<template>
  <b-card>
    <b-table
      :fields="tableFields"
      :hover="true"
      :small="true"
      :responsive="true"
      :bordered="true"
      :items="iseis"
      :busy="isBusy"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>
      <template #empty="scope">
        <div class="h5 text-center">Дані не знайдено</div>
      </template>
      <template #cell(created_at)="data">
        {{ data.index === 0 ? data.value + " - актуальний" : data.value }}
      </template>
    </b-table>
    <b-button
      type="submit"
      size="sm"
      variant="success"
      @click="createIseiModal = true"
    >
      <i class="fa fa-dot-circle-o"></i> Додати
    </b-button>

    <b-modal
      id="createIsei"
      title="Додати ISEI дані"
      hide-footer
      size="xl"
      v-model="createIseiModal"
      @ok="createIseiModal = false"
    >
      <create-isei-form />
    </b-modal>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import FinancialModelForm from "@/entity/FinancialModels/forms/FinancialModelForm.vue";
import CreateIseiForm from "@/entity/FinancialModels/forms/CreateIseiForm.vue";

export default {
  name: "IseiData",
  computed: {
    ...mapGetters({
      iseis: "financialModel/getIseis",
    }),
  },
  components: {
    CreateIseiForm,
    FinancialModelForm,
  },
  data() {
    return {
      createIseiModal: false,
      tableFields: [
        {
          key: "created_at",
          label: "Дата",
          formatter: (v) => moment(v).format("DD.MM.YYYY"),
        },
        { key: "redirect_uri", label: "Зворотнє посилання" },
        { key: "client_id", label: "Ідентифікатор клієнта" },
      ],
      report: null,
      isBusy: false,
    };
  },
  created() {
    this.isBusy = true;
    this.$store
      .dispatch("financialModel/pullIseis", this.$route.params.id)
      .then(() => (this.isBusy = false));
  },
};
</script>
