<template>
  <b-card>
    <b-table
      :fields="tableFields"
      :hover="true"
      :small="true"
      :responsive="true"
      :bordered="true"
      :items="f2hxs"
      :busy="isBusy"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>
      <template #empty="scope">
        <div class="h5 text-center">Дані не знайдено</div>
      </template>
      <template #cell(report_date)="data">
        {{ data.index === 0 ? data.value + " - актуальний" : data.value }}
      </template>
      <template #cell(causer)="data">
        {{ data.value.email }}
      </template>
      <template v-slot:cell(actions)="data">
        <b-button
          variant="ghost-info link"
          size="sm"
          @click="viewReport(data.index)"
          ><i class="fa fa-info-circle"></i
        ></b-button>
      </template>
    </b-table>
    <b-modal id="questions_view" size="xl" title="Звіт 2HX" ok-only>
      <questions-modal
        v-if="report"
        :prop-questions="report.questions"
        :prop-global-disabled="true"
      />
    </b-modal>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import QuestionsModal from "./QuestionsModal.vue";

export default {
  name: "InstitutionsRiskProfile",
  computed: {
    ...mapGetters({
      f2hxs: "financialModel/getF2hxs"
    })
  },
  components: {
    QuestionsModal
  },
  data() {
    return {
      tableFields: [
        { key: "actions", label: "Дії" },
        {
          key: "report_date",
          label: "Дата звіту",
          formatter: v => moment(v).format("DD.MM.YYYY")
        },
        { key: "causer", label: "Ініціатор" },
        {
          key: "created_at",
          label: "Час створення",
          formatter: v => moment(v).format("DD.MM.YYYY HH:mm:ss")
        }
      ],
      report: null,
      isBusy: false
    };
  },
  created() {
    this.isBusy = true;
    this.$store
      .dispatch("financialModel/pullF2hxs", this.$route.params.id)
      .then(() => (this.isBusy = false));
  },
  methods: {
    viewReport(index) {
      this.report = this.f2hxs[index];
      this.$root.$emit("bv::show::modal", "questions_view");
    }
  }
};
</script>
