<template>
  <ValidationObserver ref="formValidationMain" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні профілю СПФМ!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        name="ЄДРПОУ"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="usreou_code"
      >
        <b-form-group label="ЄДРПОУ:">
          <b-form-input
            type="text"
            v-model="form.usreou_code"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
            maxlength="8"
            placeholder="8-ми значний номер"
            :disabled="true"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        name="Тип субʼєкта"
        rules="required"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="type_id"
      >
        <b-form-group label="Тип субʼєкта">
          <b-form-select
            disabled
            text-field="name"
            value-field="id"
            v-model="form.type_id"
            :options="types"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <b-form-group
        label="Назва"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="назва"
          rules="required|max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="name"
        >
          <b-form-group label="Повна:">
            <b-form-input
              type="text"
              v-model="form.name"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Скорочена"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="short_name"
        >
          <b-form-group label="Скорочена:">
            <b-form-input
              type="text"
              v-model="form.short_name"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <accounts />
      <ValidationProvider
        name="адреса електронної пошти"
        rules="email"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="email_support"
      >
        <b-form-group
          label="Адреса електронної пошти:"
          description="no-reply@example.com"
        >
          <b-form-input
            type="email"
            v-model="form.email_support"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="номер телефону"
        :rules="{ regex: /^\+[\d]{12}$/ }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="phone_support"
      >
        <b-form-group label="Номер телефону:" description="+380960000000">
          <b-form-input
            type="text"
            v-model="form.phone_support"
            maxlength="13"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="web посилання"
        :rules="{
          regex:
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          max: 255,
        }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="site"
      >
        <b-form-group label="WEB посилання:" description="www.example.com/uk">
          <b-form-input
            type="text"
            v-model="form.site"
            maxlength="255"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <licenses />

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
        type="submit"
        size="sm"
        variant="success"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>
  </ValidationObserver>
</template>
<script>
import Accounts from "@/entity/FinancialModels/components/Accounts.vue";
import Licenses from "@/entity/FinancialModels/components/Licenses.vue";
import mixins from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "MainProfile",
  props: ["financialModel"],
  mixins: [mixins],
  components: { Licenses, Accounts },
  data() {
    return {
      form: {
        id: null,
        usreou_code: "",
        type_id: "",
        name: "",
        short_name: "",
        email_support: "",
        phone_support: "",
        site: "",
      },
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allFinancialModelsTypes",
    }),
  },
  watch: {
    financialModel: {
      deep: true,
      handler(value) {
        this.form.id = value.id;
        this.form.usreou_code = value.usreou_code || "";
        this.form.type_id = value.type.id || "";
        this.form.name = value.name || "";
        this.form.short_name = value.short_name || "";
        this.form.email_support = value.email_support || "";
        this.form.phone_support = value.phone_support || "";
        this.form.site = value.site || "";

        requestAnimationFrame(() => {
          this.$refs.formValidationMain.reset();
        });
      },
    },
  },
  methods: {
    onSubmit() {
      this.errors = {};

      this.$store
        .dispatch(
          "financialModel/update",
          this.getChangedFields(this.$refs.formValidationMain.fields, this.form)
        )
        .then(() => {
          this.$store.dispatch("financialModels/pull");
        })
        .catch((response) => {
          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidationMain.setErrors(this.errors);
        });
    },
  },
};
</script>
