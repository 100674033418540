<template>
  <ValidationObserver ref="formValidationAccount" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні акаунту профілю СПФМ!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Рахунок"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Банк"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="bank"
        >
          <b-form-group label="Банк:">
            <b-form-input
              type="text"
              v-model="form.bank"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="МФО банку"
          vid="bank_code"
          :rules="{ regex: /^[\d]{6}$/ }"
          v-slot="{ errors, dirty, validated, valid, changed }"
        >
          <b-form-group label="МФО банку:">
            <b-form-input
              type="text"
              v-model="form.bank_code"
              maxlength="6"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Номер рахунку"
          :rules="{ required: true, regex: /^[A-Z]{2}[\d]{27}$/ }"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="iban"
        >
          <b-form-group
            label="Рахунок:"
            description="UA000000000000000000000000000"
          >
            <b-form-input
              type="text"
              v-model="form.iban"
              maxlength="29"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              :disabled="form.id !== null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <div slot="footer">
        <b-button
          type="submit"
          size="sm"
          :variant="propAccount ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propAccount ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import mixins from "@/mixins";
export default {
  name: "AccountForm",
  mixins: [mixins],
  props: {
    propAccount: {
      type: Object | null,
      required: true
    }
  },
  data() {
    return {
      errors: {},
      form: {
        id: null,
        bank: "",
        bank_code: "",
        iban: ""
      }
    };
  },
  created() {
    Object.assign(this.form, this.propAccount);
  },
  methods: {
    onSubmit() {
      let fields = this.getChangedFields(
        this.$refs.formValidationAccount.fields,
        this.form
      );

      fields.financial_model_id = this.$route.params.id;
      this.$store
        .dispatch(
          this.propAccount
            ? "financialModel/updateAccount"
            : "financialModel/createAccount",
          fields
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalAccount");
        });
    }
  }
};
</script>
