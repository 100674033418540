<template>
  <div>
    <b-form-group label="Завантажити печатку" label-cols="2" :horizontal="true">
      <b-row>
        <b-col
          ><b-form-file
            v-model="newStamp"
            placeholder="Оберіть зображення..."
            drop-placeholder="Киньте зображення сюди..."
            browse-text="Пошук"
            accept=".jpg, .jpeg"
        /></b-col>
        <b-col>
          <b-button
            variant="primary"
            size="sm"
            @click="uploadStamp"
            style="margin-top: 1%"
            :disabled="!Boolean(newStamp)"
            ><i class="fa fa-dot-circle-o"></i> Зберегти
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Додані печатки"
      label-size="lg"
      label-class="font-weight-bold"
    >
      <b-table
        :fields="tableFields"
        :hover="true"
        :small="true"
        :responsive="true"
        :bordered="true"
        :items="stamps"
      >
        <template v-slot:cell(actions)="data">
          <b-button
            variant="ghost-info link"
            size="sm"
            @click="downloadStamp(data.item.id)"
            ><i class="fa fa-save"></i
          ></b-button>
          <b-button
            variant="link text-success"
            size="sm"
            @click="viewStamp(data.item.id)"
            ><i class="fa fa-info-circle"></i
          ></b-button>
        </template>
      </b-table>
      <b-modal id="stamp_view" size="xl" title="Печатка" ok-only>
        <b-embed ref="stamp-src" type="embed" :src="stampFile" />
      </b-modal>
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  name: "Stamps",
  data() {
    return {
      newStamp: null,
      tableFields: [
        { key: "id", label: "Id печати" },
        { key: "user_added_id", label: "Id юзера" },
        {
          key: "created_at",
          label: "Час завантаження",
          formatter: v => (v ? moment(v).format("DD.MM.YYYY HH:mm:ss") : null)
        },
        { key: "actions", label: "Дії" }
      ],
      stampFile: null
    };
  },
  computed: {
    ...mapGetters({
      stamps: "financialModel/getStamps"
    })
  },
  methods: {
    uploadStamp() {
      let form = new FormData();
      form.set("stamp", this.newStamp);
      this.$store
        .dispatch("financialModel/uploadStamp", [this.$route.params.id, form])
        .then(() => {
          this.newStamp = null;
        });
    },
    downloadStamp(id) {
      this.$store
        .dispatch("financialModel/getStampById", id)
        .then(response => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${id}.jpeg`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$snotify.error("Печатку не знайдено");
        });
    },
    viewStamp(id) {
      this.$store
        .dispatch("financialModel/getStampById", id)
        .then(response => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const blobToBase64 = blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          };
          blobToBase64(blob).then(res => {
            this.stampFile = `${res}`;
            this.$root.$emit("bv::show::modal", "stamp_view");
          });
        })
        .catch(() => {
          this.$snotify.error("Печатку не знайдено");
        });
    }
  }
};
</script>
